import React, { useState } from "react";
import { heartIcon } from "./Icons.js";

const Footer = ({ t, i18n }) => {
  
  return (
    <footer className="footer">
      <a href="">Designed & built with {heartIcon()} by David vital.</a>
    </footer>
  );
};

export default Footer;