export default {
  translations: { // Mesmo valor usado nas configurações (defaultNS)
    home: {
      welcome: "Welcome to my portfolio",
      introduction: 'Hello, my name is David.',
      help: "Type 'help' to see a list of commands...",

      home: "Inicio",
      aboutMe: "Sobre Mim",
      Projects: "Projetos",
      Contact: "Contato",
      changeLang: "Mudar Idioma",
      sectionIntroduction: "Olá, meu nome é",
      heroH1: "David vital",
      heroText: "Eu sou um desenvolvedor de software especializado na construção (e ocasionalmente no design) de experiências digitais excepcionais. Atualmente, estou focado na construção de alguns projetos pessoais.",
      letsTalk: "Vamos conversar",
      resume: "Curriculo",
      sectionAbout: "Sobre mim.",
      aboutText0: "Oi, sou o David — um programador e designer. Navego na mundo digital, não apenas construindo software, mas esculpindo experiências que intrigam e cativam.",
      aboutText1: "Minha jornada começou com uma imersão impulsionada pela curiosidade no mundo dos computadores, evoluindo para uma paixão por criar código e design que desafia limites. Desde meu primeiro 'Olá, mundo' em Pascal até o techstack com tecnologias modernas, estou em constante busca pelo ponto ideal onde código encontra criatividade.",
      aboutText2: "Como desenvolvedor de software, eu arquiteto soluções robustas, transformando linhas de código em elegância funcional. Simultaneamente, como designer de UI/UX, eu coreografo histórias visuais, garantindo que cada interação seja um momento de prazer sem falhas.",
      sectionProjects: "Alguns dos meus projetos",
      projectsDesc: "Eu gosto de criar experiências incrives e úteis para o usuário final. Aqui está uma pequena galeria dos meus projetos recentes.",
      projectsMore: "Outros projetos podem ser explorados no ",
      projectsMoreLink: "meu perfil no github",
      currentlyWorking: "Projeto em desenvolvimento",
      more: "Other projects can be explored in my github profile",
      sectionContact: "Diga, Olá",
      contactTitle: "Vamos trabalhar juntos",
      contactText: "Totalmente aberto a novas possibilidades e conexões! Entre em contato se tiver algum empreendimento interessante, perguntas ou apenas quiser bater um papo. Farei o meu melhor para responder prontamente!",
      contactButton: "Vamos colaborar",
      pt: "Português",
      en: "Inglês"
    }
  }
}